import React, { useEffect, useState } from "react";
import SeeList from "../components/seeListButton";
import Chart from "react-apexcharts";
import HeatMap from "../components/heatmap";
import AnimatedNumbers from "react-animated-numbers";
import "../styles/StatHaven.css";
import taskService from "../api/taskService";
import { getTodaysDate } from "../utils/generalUtils";

const StatHaven = () => {
    const [totalTasksDone, setTotalTasksDone] = useState(0);
    const [tasks, setTasks] = useState([]);
    const [daysLeft, setDaysLeft] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const fetchedTasks = await taskService.getAllUserTasks();
            const data = fetchedTasks.data;

            calculateWorkload(data ? data : []);
            setTasks(data);
        };

        fetchData();
    }, []);

    const calculateWorkload = (tasks) => {
        const semStart = new Date("2025-01-06");
        const semEnd = new Date("2025-04-31");

        // Calculate days left
        const today = new Date(getTodaysDate());

        let diffDays = 0;
        if (today < semEnd) {
            const diffTime = semEnd.getTime() - today.getTime();
            diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        }

        setDaysLeft(diffDays);

        let totalDone = 0;
        for (const task of tasks) {
            const date = new Date(task.due_date);
            if (date < semStart || date > semEnd) {
                continue;
            }
            if (task.status === 2 || new Date(task.due_date) < new Date()) {
                totalDone++;
            }
        }
        setTotalTasksDone((totalDone / tasks.length) * 100);
    };

    const options_amountOfSemDone = {
        chart: {
            height: 350,
            type: "radialBar",
            name: "Semester Progress",
            fontFamily: "MyOutfit-L, sans-serif",
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 270,
                hollow: {
                    margin: 0,
                    size: "70%",
                    background: "#fff",
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: "front",
                    dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24,
                    },
                },
                track: {
                    strokeWidth: "67%",
                    margin: 0, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: -3,
                        left: 0,
                        blur: 4,
                        opacity: 0.35,
                    },
                },

                dataLabels: {
                    show: true,
                    name: {
                        offsetY: -10,
                        show: true,
                        color: "#888",
                        fontSize: "17px",
                    },
                    value: {
                        formatter: function (val) {
                            return parseInt(val) + "%";
                        },
                        color: "#111",
                        fontSize: "36px",
                        show: true,
                    },
                },
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                gradientToColors: ["#FF5B5B", "#FF5B5B", "#FF5B5B"],
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        stroke: {
            lineCap: "round",
        },
        colors: ["#C779D0"],
        labels: ["Semester Progress"],
    };

    return (
        <div className="stat-haven">
            <SeeList /> Stats &#128526;
            <div className="pie-charts">
                <Chart
                    options={options_amountOfSemDone}
                    series={[totalTasksDone]}
                    type="radialBar"
                    height={350}
                />
                <div className="days-left">
                    {daysLeft !== null && (
                        <>
                            <h1>
                                <AnimatedNumbers
                                    animateToNumber={daysLeft}
                                    config={{
                                        duration: 1000,
                                        stepPrecision: 2,
                                    }}
                                />
                            </h1>
                            <p>Days Left To End Of Semester</p>
                        </>
                    )}
                </div>
            </div>
            <div className="workload-charts">
                <div id="heatmap">
                    <HeatMap
                        tasks={tasks}
                        semester_info={{
                            year: 2025,
                            semester: 2,
                        }}
                        chartType="numOfTasks"
                    />
                </div>
                <div id="heatmap">
                    <HeatMap
                        tasks={tasks}
                        semester_info={{
                            year: 2025,
                            semester: 2,
                        }}
                        chartType="weightedTasks"
                    />
                </div>
            </div>
        </div>
    );
};

export default StatHaven;
